
<script>
import { Doughnut } from 'vue-chartjs'

export default {
  name: 'DoughnutChartElement',
  extends: Doughnut,
  props: {
    data: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      options: {
        legend: {
          display: false,
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.data, this.options)
  },
  methods: {
    update() {
      this.$data._chart.update()
    }
  }
}
</script>
